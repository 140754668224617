<template>
	<!-- 掉量、超成本、流量位 -->
	<div class="ticketCreate">
        <!-- <div class="head padd20 borbut20">
			1
		</div> -->
        <div class="tab padd20">
			<div class="duoshao">
                <div class="title">掉量超成本</div>
                <!-- <el-button type="primary" id="AddAccount" @click="addzhanghu($event)" class="yinyinga" icon="el-icon-plus">新增账户</el-button> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="ticketTable" :data="ticketTableList" style="width: 100%">
				<el-table-column prop="id" label="ID"></el-table-column>
				<el-table-column prop="is_operating_type" label="用户手机号">
					<template slot-scope="scope">
						{{scope.row.user ? scope.row.user.account : '--'}}
                    </template>
				</el-table-column>
				<el-table-column prop="order_title" label="工单标题"></el-table-column>
				<el-table-column prop="advertiser_name" label="广告主名称"></el-table-column>
				<el-table-column prop="created_at" label="提交时间"></el-table-column>
				<el-table-column prop="is_operating_type" label="详情">
					<template slot-scope="scope">
						<el-button type="primary" @click="onDetails(scope.row)">工单详情</el-button>
                    </template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="onget_netflow_orders" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
			ticketTableList:[],//列表
		}
	},
	
	mounted(){
		this.onget_netflow_orders()
	},

	methods: {
		// 测试用
		onget_netflow_orders(val){
			var param = {
				page:val,
				page_num:this.per_page,
			}
			this.$service.get(this.$api.get_netflow_orders,param,(res)=> {
				if(res.code == '200'){
					this.ticketTableList = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		// 跳转详情
		onDetails(row){
			this.$router.push('/ticketCreate/ticketCreateInfo?id=' + row.id)
		}

		
	}
}
</script>

<style lang="scss" scoped>
.ticketCreate{
	
}
</style>
<style lang="scss">

</style>
